import styles from './index.css';
import { h } from 'preact';
import classnames from 'classnames';
import { connect } from 'unistore/preact';
import { logError } from 'services/logger';
import { post, getOnboarding } from 'services/restApi';
import { CloseButton } from 'library/components/ui/iconButton2/closeButton';
import { ONBOARDING, CLOSED, USER, store, TOUR_ENABLED, TOOLTIPS, ONBOARDING_API_STATUS } from 'util/store';
import { getLocalStorage, setLocalStorage } from 'util/index';
import PropTypes from 'prop-types';
import { setLastClickedTargetFromEvent } from '@pogo-internal/web-vitals';
import useSilentAuth from 'library/hooks/useSilentAuth';
import { load } from 'services/urlService';
import { TOOLTIP, VIEW_PROMOTION  } from '@pogo-internal/events/events';
import { dispatchEvent } from '@pogo-internal/events/eventManager';
import { PROMOTION_ID, TOOLTIP_NAME, TOOLTIP_OPERATION, TOOLTIP_LOCATION } from '@pogo-internal/events/attributes';
import { useEffect } from 'preact/hooks';
import TextWithHtml from 'library/components/util/textWithHtml';

const onboardingUnAuthorizedMap = { inbox: ['FREE'] };

/**
 * Initializes the onboarding state.
 * Retrieves onboarding data from the server and updates the state.
 * @async
 * @function initOnboarding
 */

export async function initOnboarding() {
	let { [ONBOARDING]: onboarding, [USER]: user, [ONBOARDING_API_STATUS]: onboardingApiStatus } = store.getState();
	if (!onboardingApiStatus) {
		try {
			let localTooltips = getLocalStorage('tooltips') || {};
			// TODO: TECH DEBT: remove [USER]: {} from pogoview/util/store then the Object.keys.length check can be removed
			if (user && Object.keys(user).length) {
				store.setState({ [ONBOARDING_API_STATUS]: 'loading' });
				onboarding = await getOnboarding();
			}

			const onboardingTooltips = onboarding?.tooltips || {};

			// Temporary fix for Free users seeing inbox route onboarding
			for (let route in onboardingUnAuthorizedMap) {
				if (onboardingUnAuthorizedMap[route].includes(user.authLevel) && onboarding) {
					onboarding.interstitials[route] = null;
				}
			}

			//  if the user is a guest, the onboarding tooltips should take priority to the local storage
			const tooltips = user.authLevel === 'GUEST' ?
				{ ...onboardingTooltips , ...localTooltips } :
				{ ...localTooltips, ...onboardingTooltips };

			store.setState({
				[ONBOARDING_API_STATUS]: 'loaded',
				[ONBOARDING]: {
					...(onboarding || {}),
					[TOOLTIPS]: tooltips
				}
			});
		}
		catch (e) {
			logError(e, 'Tooltip - initOnboarding');
		}
	}
}

/**
 * @async
 * @function closeTooltip
 * @param {string} tooltipId - The ID of the tooltip to close.
 * @desc Closes the specified tooltip and updates the onboarding state.
 */
async function closeTooltip(tooltipId) {
	const { [ONBOARDING]: onboarding, [USER]: user } = store.getState();
	try {
		store.setState({
			[ONBOARDING]: {
				...onboarding,
				[TOOLTIPS]: {
					...onboarding.tooltips,
					[tooltipId]: {
						[CLOSED]: true
					}
				}
			}
		});
		if (user && user.authenticated && !onboarding.tooltips[tooltipId].localStorageOnly) {
			await post(`/server/rest/onboarding/closeTooltip?location=${tooltipId}`);
		}
		else {
			setLocalStorage(
				`tooltips`,
				{
					...onboarding.tooltips,
					[tooltipId]: {
						[CLOSED]: true
					}
				}
			);
		}
	}
	catch (e) {
		logError(e, 'Tooltip - closeTooltip');
	}
}
const mapStateToProps = (
	{
		[ONBOARDING_API_STATUS]: onboardingApiStatus,
		[ONBOARDING]: onboarding,
		[USER]: user ,
		[TOUR_ENABLED]: tourEnabled
	},
	{
		tooltipId
	}) => ({
	tooltip: onboardingApiStatus === 'loaded' && onboarding?.tooltips?.[tooltipId],
	user,
	tourEnabled
});

/**
 * @component  OnboardingTooltip
 * @param {string} additionalClass - Additional CSS class for the tooltip.
 * @param {string} arrowPosition - The position of the tooltip arrow, default position is top.
 * @param {Object} tooltip - The tooltip data, includes title, copy, hrefKey, and hrefText.
 * @param {string} tooltipId - The ID of the tooltip.
 * @param {Object} user - The user data.
 * @param {boolean} tourEnabled - Flag indicating if the tour is enabled.
 * @param {boolean} showTooltip - Flag indicating if the tooltip should be shown.
 * @param {string} location - The location of the tooltip using for the telemetry.
 * @returns {JSX.Element|null} - The rendered component.
 * @desc Renders the onboarding tooltip component.
 */

const OnboardingTooltip = ({
	class: additionalClass,
	arrowPosition = 'top',
	tooltip,
	tooltipId,
	user,
	tourEnabled,
	showTooltip,
	location
}) => {
	useSilentAuth(user && Object.keys(user)?.length ? user : undefined, initOnboarding); // TODO: TECH DEBT: this is a temp check until we remove [USER]: {} from initialState in h5sdk store
	const { title, copy, hrefKey, hrefText } = tooltip || {};
	const hasPromoHrefKey = ['register','clubPogo'].includes(hrefKey);

	function onCloseTooltip(event) {
		setLastClickedTargetFromEvent(event);
		event.stopPropagation();
		closeTooltip(tooltipId);
		dispatchEvent(TOOLTIP, { [TOOLTIP_NAME]: tooltipId, [TOOLTIP_OPERATION]: 'close', [TOOLTIP_LOCATION]: location });
	}

	function isOnboardingTooltipClosed() {
		// tourEnabled is a flag to identify if the tour is visible
		if (tourEnabled) {
			return true;
		}
		else if (tooltip) {
			return tooltip[CLOSED];
		}
		return true;
	}

	function onLinkClick(event){
		onCloseTooltip(event);
		const intcmp = hasPromoHrefKey ? `${tooltipId}_tooltip` : undefined;
		load(tooltip.hrefKey, tooltipId, undefined, true, intcmp, '_blank');
	}

	useEffect(() => {
		if (!isOnboardingTooltipClosed() && showTooltip) {
			dispatchEvent(TOOLTIP, { [TOOLTIP_NAME]: tooltipId, [TOOLTIP_OPERATION]: 'show', [TOOLTIP_LOCATION]: location });
			if (hasPromoHrefKey){
				dispatchEvent(VIEW_PROMOTION, { [PROMOTION_ID]: `${tooltipId}_tooltip` });
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, showTooltip, tooltipId, hasPromoHrefKey]);

	// TODO: investigate why we need showTooltip here; ideally we can avoid rendering the component entirely if showTooltip = false
	if (!isOnboardingTooltipClosed() && showTooltip && copy) {
		return  (
			<div class={classnames(styles.onboardingTooltip, additionalClass)}>
				<div class={classnames(styles.tooltip, styles[arrowPosition])}>
					<div class={styles.title}>{title}</div>
					<div class={styles.copy}>
						<TextWithHtml text={copy} location={location} />
						{
							// TODO: should be able to build this into the copy string and pass into TextWithHtml
							hrefKey && hrefText &&
							<div
								class={styles.link}
								onClick={onLinkClick}
							>
								{hrefText}
							</div>
						}
					</div>
					<CloseButton
						class={styles.closeButton}
						onClick={onCloseTooltip}
						size={'small'}
					/>
				</div>
			</div>
		);
	}

	return null;
};

OnboardingTooltip.propTypes = {
	class: PropTypes.string,
	arrowPosition: PropTypes.string,
	tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	initOnboarding: PropTypes.func,
	closeTooltip: PropTypes.func,
	tooltipId: PropTypes.string,
	user: PropTypes.object,
	tourEnabled: PropTypes.bool,
	showTooltip: PropTypes.bool,
	location: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(OnboardingTooltip);
