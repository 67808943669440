/* globals POGO_ENVIRONMENT, MPULSE_ENABLED */
import { advertisementCookiesEnabled, functionalCookiesEnabled, isEurope, isUsa } from 'services/privacy';
import { getCookie, once, promisify, withRetries } from 'util/index';
import { logError } from 'services/logger';
import { CONSENT_MANAGER_LOADED, SHOW_CONSENT_BANNER, store } from 'util/store';
import { enableGa4, TAG_ID } from 'services/analytics/ga4';
import { addIABStub } from 'vendor/iab';
import { enableExperimentTracking, fetchAndSetExperimentsById } from 'services/experimentation';
import { get } from './restApi';
import { enableMpulse } from '../../vendor/mPulse';

window.ramp = window.ramp || {};
window._pwGA4PageviewId = ''.concat(Date.now());

const ADS_CONFIG = {
	pogodev: { siteId: 74650, ga4: 'G-DE9QQVPX9F' },
	alpha: { siteId: 74650, ga4: 'G-DE9QQVPX9F' }, // TODO change this
	prod: { siteId: 74264, ga4: 'G-BQTFY2W1F3' }
};

// default user is used for unauthenticated pages with authType: none
const DEFAULT_USER = {
	segments: [],
	underAge: true,
	age: -1
};

// eslint-disable-next-line prefer-rest-params
function gtag(){ window.dataLayer.push(arguments); }

// mPulse
try {
	if (functionalCookiesEnabled(DEFAULT_USER) && MPULSE_ENABLED) {
		enableMpulse();
	}
}
catch (e) {
	// no need to log mpulse errors
}

export const loadExternalScripts = once(async (user = DEFAULT_USER, route) => {
	try {
		let { showConsentBanner = true, loadAdsScript = true } = route;

		await loadConsentManager(user, showConsentBanner);

		gtag('consent', 'default', {
			ad_storage: advertisementCookiesEnabled(user) ? 'granted' : 'denied',
			ad_user_data: advertisementCookiesEnabled(user) ? 'granted' : 'denied',
			ad_personalization: advertisementCookiesEnabled(user) ? 'granted' : 'denied',
			analytics_storage: (functionalCookiesEnabled(user) || isUsa()) ? 'granted' : 'denied'
		});

		// we need to wait until consent manager has loaded before making calls to experimentation
		fetchAndSetExperimentsById();
		enableExperimentTracking();

		// Google Analytics
		if (functionalCookiesEnabled(user) || isUsa()) {
			enableGa4(user);
			load('https://www.googletagmanager.com/gtag/js?id=' + TAG_ID, true, undefined,
				'gtag', 'external-loadGtagScript');
		}

		// Ads script
		if (!user.club && loadAdsScript && (functionalCookiesEnabled(user) || isUsa())) {
			setPlaywirePrivacyFlags(user);
			load(`https://cdn.intergient.com/1024962/${ADS_CONFIG[POGO_ENVIRONMENT].siteId}/ramp.js`, true,
				undefined, 'playWire', 'external-loadPlaywireScript');

			// Google Analytics for playwire
			gtag('config', ADS_CONFIG[POGO_ENVIRONMENT].ga4, { send_page_view: false });
			gtag('event', 'ramp_js', {
				send_to: ADS_CONFIG[POGO_ENVIRONMENT].ga4,
				pageview_id: window._pwGA4PageviewId
			});
		}
	}
	catch (e) {
		logError(e, 'external - loadExternalScripts');
	}
});

async function loadConsentManager(user, showConsentBanner) {
	addIABStub();

	await load('https://consent.trustarc.com/notice?domain=pogo_iab_22.com&c=teconsent&js=nj&noticeType=bb&text=true&pcookie=1&gtm=1',
		true, 'anonymous', 'trustarc', 'external-loadConsentManager');

	await promisify(() => !!getCookie('notice_behavior'), 100, 100);

	if (!user.underAge && showConsentBanner) {
		store.setState({ [SHOW_CONSENT_BANNER]: true });
	}
	store.setState({ [CONSENT_MANAGER_LOADED]: true });
}

function setPlaywirePrivacyFlags(user) {
	window.ramp.privacy = {
		advertisementCookiesEnabled: !!advertisementCookiesEnabled(user),
		functionalCookiesEnabled: !!functionalCookiesEnabled(user)
	};
	if (user.underAge) {
		window.ramp.privacy.prebidNpa = true;
		window.ramp.privacy.amazonNpa = true;
		window.ramp.privacy.disableDMP = true;
	}
	let flags = {};
	if (!advertisementCookiesEnabled(user) || user.underAge) {
		if (isUsa()) {
			flags.rdp = true;
		}
		else {
			flags.npa = true;
		}
		if (user.underAge) {
			if (isEurope()) {
				flags.tfua = true;
			}
			else {
				flags.tfcd = true;
			}
		}
	}
	window.ramp.privacy.flags = flags;
}

async function load(src, async, crossOrigin, id, location) {
	try {
		await addScript(src, async, crossOrigin, id + '-script');
		get(`/server/script/${id}/success`); // used to keep count of load success
	}
	catch (e) {
		logError(new Error(`error loading ${id} script`), location);
		get(`/server/script/${id}/fail`);// used to keep count of load fail
	}
}

/**
 * adds script function that supports retries, resolves if script
 * is loaded and rejects if all retries have been exhausted
 */
async function addScript(src, async, crossOrigin, id) {
	let maxRetries = store.getState()?.user?.config?.loadJsMaxRetries || 0;
	return withRetries(() => _addScript(src, async, crossOrigin, id), maxRetries);
}

async function _addScript(src, async, crossOrigin, id) {
	let head = document.getElementsByTagName('head')[0];
	let script = document.createElement('script');
	let loadPromise = new Promise((resolve, reject) => {
		script.onload = () => resolve();
		script.onerror = () => reject(new Error('error loading script'));
	});
	script.crossOrigin = crossOrigin;
	script.async = async;
	script.src = src;
	script.id = id;
	head.insertBefore(script, head.lastChild);
	return loadPromise;
}