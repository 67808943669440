import { Component, h } from 'preact';
import { boundMethod } from 'autobind-decorator';
import { loadUrl } from 'services/urlService';
import { logError } from 'services/logger';
import Page from 'components/framework/page';
import ErrorPage from 'routes/error';
import Loading from 'library/components/common/loading';
import { get } from 'services/restApi';
import PropTypes from 'prop-types';

export function getRoute(url) {
	return get(`/server/route`, { url }, {});
}

export default class DynamicPage extends Component {

	componentDidMount() {
		if (this.props.url) {
			this.init();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.url !== prevProps.url) {
			this.init();
		}
	}

	@boundMethod
	async init() {
		try {
			this.setState({ component: undefined, route: undefined }); // reset
			const page = await getRoute(this.props.url.split('?')[0]);
			if (page.redirect) {
				loadUrl(page.redirect + window.location.search, '', '', false);
			}
			else if (page.route) {
				let route = page.route;
				try {
					let component = require(`routes/${route.component}/index.js`).default;
					this.setState({ component, route });
				}
				catch (e) {
					this.setState({ error: true });
				}
			}
			else {
				this.setState({ error: true });
			}
		}
		catch (e) {
			logError(e, 'dynamicPage init');
			this.setState({ error: true });
		}
	}

	render({ url }, { component: Component, route, error }) {
		if (route && Component) {
			return <Page url={url} body={Component} route={route} />;
		}
		if (error) {
			return <Page url={url} body={ErrorPage} route={{ component: 'error' }} />;
		}
		return (
			<div>
				<Loading flavor={'page'} />
			</div>
		);
	}
}

DynamicPage.propTypes = {
	url: PropTypes.string
};
