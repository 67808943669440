/* globals  CLIENT_VERSION */
import { logError } from 'services/logger';
import { once, onReady } from 'util/index';

export const updateServiceWorker = once( async (user) => {
	try {
		if ('serviceWorker' in navigator && user) {
			if (user.segments.includes('serviceWorker')) {
				await onReady();
				await navigator.serviceWorker.register('/service-worker.js');
				const registration = await navigator.serviceWorker.ready;
				if (user.clientVersion > +CLIENT_VERSION) {
					registration.active.postMessage('Update html cache',);
				}
			}
			else {
				unregister();
			}
		}
	}
	catch (e) {
		logError(e, 'service worker registration');
	}
});

export async function unregister() {
	navigator.serviceWorker.getRegistrations()
		.then((registrations) => {
			for (let registration of registrations) {
				registration.unregister();
			}
			return null;
		})
		.catch(e => {}); // ignore errors while unregistering
	indexedDB.deleteDatabase('workbox-expiration');
	const cacheKeys = await caches.keys();
	for (const key of cacheKeys) {
		await caches.delete(key);
	}
}
