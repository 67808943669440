/* globals 	SILENT_AUTH_COOKIE_NAME */
import { h, Component, Fragment } from 'preact';
import { Suspense, lazy } from 'preact/compat';
import { connect } from 'unistore/preact';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './index.css';
import Error from 'routes/error';
import { logError } from 'services/logger';
import { loadExternalScripts } from 'services/external';
import Header from 'components/common/header/container';
import Footer from 'components/common/footer';
import SocialSdk from 'async?name=socialSdk!components/social/sdk';
import FriendsContainer from 'async?name=friendsContainer!components/social/friends/friendsContainer';
import PresenceContainer from 'async?name=presenceContainer!components/social/presence/presenceContainer';
import SystemMessages from 'async?name=systemMessages!components/common/systemMessages';
import {
	onAppScroll, once, removeOnAppScroll, scrollToTop, showError, onReady,
	showAlert, getCookie, getFromFragment, removeQueryParam, getLocalStorage,
	setLocalStorage
} from 'util/index';
import { getQueryStringParams, isPrerender } from 'library/util';
import { AnchoredAd } from 'components/common/ad';
import {
	COPPA_ALERT, ERROR, PREV_URL, ROUTE, URL, USER,
	SHOW_CONSENT_BANNER, initialState, store, RTM_CONNECTION_STATE, HEADER_COPY, SCREEN_BREAKPOINTS
} from 'util/store';
import { addSeoTags } from 'services/seo';
import { updateServiceWorker, unregister } from 'services/serviceWorker';
import { load, setScrollTelemetryFunction } from 'services/urlService';
import { getUser } from 'services/restApi';
import { isEurope } from 'services/privacy';
import { attemptSilentAuth, SILENT_AUTH_ATTEMPTED } from 'services/authenticate';
import { CONNECTION_STATE_UNRECOVERABLE } from 'util/social';
import { dispatchEvent } from '@pogo-internal/events/eventManager';
import { PAGE_VIEW, SCROLL } from '@pogo-internal/events/events';
import { PAGE_PATH, PAGE_TYPE, SCROLL_PERCENT, SEND_SEGMENTS } from '@pogo-internal/events/attributes';
import { trackExperiment } from 'services/experimentation';
import { PAGE_CONTENT } from 'library/components/common/portal';
import ChallengeAccessTour from 'async?name=challengeAccessTour!components/onboarding/tours/challengeAccessTour';
import { equalOrAboveBreakpoint, onWindowResize, removeOnWindowResize  } from 'library/util/index';
import { boundMethod } from 'autobind-decorator';
const Alerts = lazy(() => import('components/alerts/alertContainer'));
import SurveyContainer from 'async?name=surveyContainer!library/components/common/survey/container';

let previousUrl, userUpdateTime = getLocalStorage('userUpdateTime'),
	throttleEvents = once(app => {
		throttle('scroll', 'optimizedScroll', app);
		throttle('resize', 'optimizedResize', window);
	});

window.pogo = window.pogo || {};
let trackedScrollPercent = 0;

/**
 * This is the base class for all pages in the venus client.
 */
class Page extends Component {
	async componentDidMount() {
		throttleEvents(document.getElementById('app'));
		onAppScroll(calculateScrollPercentage);
		await onReady();
		this.setState({ loadSocial: true });
		this.onResize();
		onWindowResize(this.onResize);
	}
	
	componentWillUnmount() {
		removeOnAppScroll(calculateScrollPercentage);
		removeOnWindowResize(this.onResize);
	}

	@boundMethod
	onResize() {
		store.setState({
			[SCREEN_BREAKPOINTS]: {
				isSmallPlus: equalOrAboveBreakpoint('--enhance-small-plus'),
				isSmallPlusPlus: equalOrAboveBreakpoint('--enhance-small-plus-plus'),
				isMediumPlus: equalOrAboveBreakpoint('--enhance-medium-plus'),
				isMediumPlusPlus: equalOrAboveBreakpoint('--enhance-medium-plus-plus'),
				isLargePlus: equalOrAboveBreakpoint('--enhance-large-plus'),
				isXLargePlus: equalOrAboveBreakpoint('--enhance-xlarge-plus'),
				isXLargePlusPlus: equalOrAboveBreakpoint('--enhance-xlarge-plus-plus')
			}
		});
	}
	render({
		url,
		user,
		route,
		body: Body,
		error,
		showConsentBanner,
		headerCopy: storeHeaderCopy
	},
	{
		loadSocial
	}) {
		if (url && previousUrl !== url) {
			onNavigate(route, url, previousUrl);
			previousUrl && scrollToTop(100);
			resetScrollTelemetry();
			previousUrl = url;
		}

		let { showAnchoredAd = true, hasCarousel = true, header = true, headerCopy,
			footer = true, spotlight = true, systemMessages = true, social = true, theme } = route;

		if (error || route.component === 'error') {
			showAnchoredAd = false;
			header = 'simple';
			spotlight = false;
			systemMessages = false;
		}

		let isChat = false;
		if (route.component === 'chat') { // TODO remove this and figure out a better way.
			isChat = true;
		}

		if (user && user.club) {
			showAnchoredAd = false;
		}

		return (
			<div id="app" class={styles.app}>
				<div class={classnames(styles.content, isChat && styles.chatHeight)} id={PAGE_CONTENT}>
					{
						header && <Header key={`${url}-header`} type={header} headerCopy={headerCopy || storeHeaderCopy} />
					}
					<div class={classnames(styles.body, spotlight && styles.spotlight,
						hasCarousel && styles.hasCarousel, styles[theme], route.component !== 'game' && styles.notGame)}
						 style={route.backgroundImage ? `background-image: ${route.backgroundImage}` : ''}
					>
						{
							error ? <Error error={error} /> :
								<Body key={`${url}-${user ? 'content' : 'skeleton'}`} {...route} {...getQueryStringParams()} />
						}
					</div>
					<div class={styles.stickyContainer}>
						{
							systemMessages && <SystemMessages key={`${url}-system-messages`} path={url} />
						}
						{
							showAnchoredAd && <AnchoredAd class={styles.anchoredAd} key={`${url}-anchored-ad`} component={route.component} location={'anchored'} />
						}
					</div>
					{
						user?.authenticated &&
						<ChallengeAccessTour />
					}
					{
						<Footer component={route.component} class={!footer ? styles.hidden : ''} />
					}
					{
						<div id="consent_blackbar" class={classnames(styles.consent_blackbar, !showConsentBanner && styles.hidden, isEurope() && styles.eu)} />
					}
					{
						!!user && url &&
						<SurveyContainer key={`${url}-survey-container`} url={url} />
					}
					<Suspense fallback={null}>
						<Alerts />
					</Suspense>
				</div>
				{
					user && user.authenticated && social && loadSocial &&
					<Fragment>
						<SocialSdk />
						<FriendsContainer />
						<PresenceContainer />
					</Fragment>
				}
			</div>
		);
	}
}

Page.propTypes = {
	url: PropTypes.string,
	route: PropTypes.object
};

function calculateScrollPercentage(e) {
	let targetElement = document.getElementById('app');
	let scrollPercentage = Math.floor(targetElement.scrollTop / (targetElement.scrollHeight - window.innerHeight) * 100);
	if (scrollPercentage > trackedScrollPercent) {
		trackedScrollPercent = scrollPercentage;
	}
}

function sendScrollTelemetry() {
	let page = window.location.pathname;
	dispatchEvent(SCROLL, { [PAGE_PATH]: page, [SCROLL_PERCENT]: trackedScrollPercent });
}
setScrollTelemetryFunction(sendScrollTelemetry);

function resetScrollTelemetry() {
	setTimeout(() => {
		trackedScrollPercent = 0;
	}, 200);
}

async function onNavigate(route, url, prevUrl) {
	try {
		let updateUserInStore;
		let { user, error } = store.getState();
		window.pogo.gameCode = route.gameCode;
		error && store.setState({ [ERROR]: undefined }); // reset error state
		let authType = route.authType || 'silent';

		store.setState({
			[URL]: url, // needed for heatmap tracking
			[PREV_URL]: prevUrl, // needed to determine onNavigateBack logic
			[ROUTE]: route, // needed by onboarding component
			...initialState
		});

		if (silentAuthNeeded(authType) || isRtmConnectionUnrecoverable()) {
			let success = await attemptSilentAuth();
			if (success) {
				return;
			}
		}

		removeQueryParam(SILENT_AUTH_ATTEMPTED);

		if (!user) {
			user = await getUser();
			addAuthLevelClass(user);
			updateUserInStore = true;
			userUpdateTime = new Date().getTime();
			setLocalStorage('userUpdateTime', userUpdateTime);
		 }

		if (authType === 'auth' && !user.authenticated && !isPrerender) {
			load('signIn', undefined, undefined, false);
			return;
		 }

		if (route.over16Only && user.age > -1 && user.age < 16) {
			load('category/all', undefined, undefined, false);
			return;
		}

		if (updateUserInStore) {
			store.setState({ [USER]: user }); // TODO move this to set timeout
			trackSegments(user);
		}

		addSeoTags(route, url);

		if (!isPrerender) {
			updateServiceWorker(user);
			loadExternalScripts(user, route);
			handleCoppa(user, route, prevUrl);
			dispatchEvent(PAGE_VIEW, { [PAGE_PATH]: url, [PAGE_TYPE]: 'real', [SEND_SEGMENTS]: !!updateUserInStore });
		}
	}
	catch (error) {
		unregister();
		showError(error.status);
		logError(error, 'page - onNavigate');
	}
}

/**
 * Adds user's auth level to body class if not present
 */
function addAuthLevelClass(user) {
	if (user && user.authLevel) {
		let classes = document.body.className;
		classes = classes.replace('guest', '')
			.replace('free', '')
			.replace('club', '');
		classes = user.authLevel.toLowerCase() + ' ' + classes.trim();
		if (classes !== document.body.className) {
			document.body.className = classes;
		}
	}
}

function handleCoppa(user, route, prevUrl) {
	if (user && route.coppa !== false) {
		// if known underage user OR age unknown (-1) and this is the second page view or game brick
		if ((user.age > -1 && user.underAge) || (user.age === -1 && (isSecondPage(user, prevUrl) || route.component === 'game' || route.over16Only))) {
			showAlert(COPPA_ALERT, 'coppa-alert');
		}
	}
}

function isSecondPage(user, prevUrl) {
	if (user.segments.includes('noSpa')) {
		prevUrl = getLocalStorage('prevUrl');
		setLocalStorage('prevUrl', window.location.href);
		return prevUrl && prevUrl !== window.location.href;
	}
	return !!prevUrl;
}

function silentAuthNeeded(authType) {
	const attemptSilentAuth = getCookie(SILENT_AUTH_COOKIE_NAME) === 'true';
	const timeSinceLastUpdate = new Date().getTime() - (userUpdateTime ?? 0);
	const silentAuthAttempted = getQueryStringParams()[SILENT_AUTH_ATTEMPTED];
	return attemptSilentAuth && !silentAuthAttempted && !getFromFragment('accessToken')
		&& timeSinceLastUpdate > 14400000; // 4 hours
}

function isRtmConnectionUnrecoverable() {
	return store.getState()[RTM_CONNECTION_STATE] === CONNECTION_STATE_UNRECOVERABLE;
}

// do not export this function
function throttle(type, name, element) {
	let running = false;
	function func() {
		if (running) { return; }
		running = true;
		requestAnimationFrame(() => {
			element && element.dispatchEvent(new CustomEvent(name));
			running = false;
		});
	}
	element && element.addEventListener(type, func, { passive: true });
}

function trackSegments(user) {
	const attributes = {
		internal_segments: user.segments?.join(' ') || '',
		internal_unid: user.unid,
		...(user.authenticated && { internal_pogo_id: user.id })
	};

	if (user?.segments) {
		trackExperiment('user_api', attributes );
	}
}

export default connect(`${ ERROR }, ${ USER }, ${ SHOW_CONSENT_BANNER }, ${HEADER_COPY}, ${SCREEN_BREAKPOINTS}`)(Page);
