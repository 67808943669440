import { load } from 'services/urlService';
import { getQueryStringParams } from 'library/util';

let silentAuthInProgress;

export const SILENT_AUTH_ATTEMPTED = 'silentAuthAttempted';

/** attempts silent auth and returns success status as boolean */
export async function attemptSilentAuth() {
	if (!silentAuthInProgress) {
		silentAuthInProgress = true;
		// silentAuthAttempted param is set on server by /server/auth
		const silentAuthAttempted = getQueryStringParams()[SILENT_AUTH_ATTEMPTED];
		if (!silentAuthAttempted) { // retry auth in main window
			load('silentAuthenticate', undefined, undefined, false);
			return true;
		}
		return false;
	}
	return true;
}

export function isSilentAuthInProgress() {
	return silentAuthInProgress;
}